
import { defineComponent, provide } from "vue";
import { RESULTS_KEY, useLiveResults } from "../liveResults";

export default defineComponent({
  setup() {
    const results = useLiveResults()
    provide(RESULTS_KEY, results)
    return {
      ...results
    };
  },
});
