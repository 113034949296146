import { defineCustomElement } from 'vue'
import ExemplarResultsTicker from './components/ExemplarResultsTicker.vue'
import ExemplarLessonStats from './components/ExemplarLessonStats.vue'
import LessonStatField from './components/LessonStatField.vue'

const t = defineCustomElement(ExemplarResultsTicker)

customElements.define('exemplar-results-ticker', t)
customElements.define('exemplar-lesson-stats', defineCustomElement(ExemplarLessonStats))
customElements.define('exemplar-lesson-stat-field', defineCustomElement(LessonStatField))
