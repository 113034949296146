
import { RESULTS_KEY, useLiveResults } from '@/liveResults'
import { computed, defineComponent, inject } from 'vue'

type KEYS = 'today' | 'average' | 'month' | 'year' | 'mtd' | 'ytd'

const validKeys: KEYS[] = [
    'today', 'average', 'month', 'year', 'mtd', 'ytd'
]

const FIELD_MAP: Record<KEYS, keyof ReturnType<typeof useLiveResults>> = {
    today: 'lessonsDoneToday',
    average: 'lessonAverageMarks',
    month: 'lessonsMonth',
    year: 'lessonsYear',
    mtd: 'lessonsDoneMTD',
    ytd: 'lessonsDoneYTD'
}

const isValidKey = (key: any): key is KEYS => {
    return validKeys.includes(key)
}

export default defineComponent({
    props: {
        field: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const results = inject(RESULTS_KEY)
        return {
            loaded: computed(() => !results || results.lessonsStatsFetched.value),
            result: computed(() => {
                if(!results) {
                    return `Please wrap in a <exemplar-lesson-stats> tag`
                }
                if (!isValidKey(props.field)) {
                    return `Please use one of ${validKeys.map(f => `'${f}'`).join(',')} for field attribute as '${props.field}' is not valid`
                }
                return results![FIELD_MAP[props.field]].value
            })
        }
    }
})
