
/** is the page being refreshed */
export function isPageRefresh(): boolean {
    try {
        const entries = window.performance.getEntriesByType('navigation')
        return (entries[0] as PerformanceNavigationTiming).type === 'reload'
    } catch (e) {
        return false
    }
}
