/* eslint-disable camelcase */

import { reactive, toRefs, onMounted, onUnmounted, InjectionKey } from 'vue'
import { isPageRefresh } from './utils'

interface PortalStats {
  average_marks_today: number;
  lessons_done_ytd:    number;
  lessons_done_mtd:    number;
  lessons_done_today:  number;
}

/**
 * used by nested web component elements to share the data and logic
 */
export const RESULTS_KEY: InjectionKey<ReturnType<typeof useLiveResults>> = Symbol('RESULTS_KEY')
const SESSION_STAGE_KEY = 'exemplar-live-results'

/** shared reactive state */
const result = reactive({
  lessonAverageMarks: null as null | string,
  lessonsDoneMTD: null as null | string,
  lessonsDoneToday: null as null | string,
  lessonsDoneYTD: null as null | string,
  lessonsYear: null as null | string,
  lessonsMonth: null as null | string,
  lessonsStatsFetched: false,
  /** last executed timestamp */
  statsLastFetched: 0
})

/** ajax request is in progress */
let statsFetching = false
/** inited from session store */
let initFromSession = false

export function useLiveResults(updateInterval = 60) {
  if (!initFromSession) {
    try {
      const value = sessionStorage.getItem(SESSION_STAGE_KEY)
      if (value) {
        Object.assign(result, JSON.parse(value))
      }
      if (isPageRefresh()) {
        result.statsLastFetched = 0
      }
    // eslint-disable-next-line no-empty
    } catch (e) {}
    initFromSession = true
  }
  const fetchData = () => {
    const time = new Date()
    if (statsFetching || (result.statsLastFetched + updateInterval * 1000) > time.getTime()) {
      return Promise.resolve()
    }
    statsFetching = true
    return fetch(`https://learning.exemplar-education.com/portalstats.free?_=${time.getTime()}`).then(resp => {
      if (resp.ok) {
        return resp.json() as Promise<PortalStats>
      }
      throw new Error(`Failed request ${resp.status}:${resp.statusText}`)
    }).then((data)  => {
      result.lessonAverageMarks = `${Math.round( data.average_marks_today * 10 ) / 10}%`
      result.lessonsDoneMTD = data.lessons_done_mtd.toLocaleString()
      result.lessonsDoneYTD = data.lessons_done_ytd.toLocaleString()
      result.lessonsDoneToday = data.lessons_done_today.toLocaleString()
      result.lessonsYear = time.toLocaleString('en-GB', { year: 'numeric' }),
      result.lessonsMonth = time.toLocaleString('en-GB', { month: 'long' }),
      result.lessonsStatsFetched = true
      result.statsLastFetched = time.getTime()
      try {
        sessionStorage.setItem(SESSION_STAGE_KEY, JSON.stringify(result))
      // eslint-disable-next-line no-empty
      } catch (e) {}
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    }).then(() => {
      statsFetching = false
    })
  }

  let intervalHandle: number
  onMounted(() => {
    intervalHandle = window.setInterval(fetchData, updateInterval * 1000)
    fetchData()
  })
  onUnmounted(() => {
    window.clearInterval(intervalHandle)
  })
  return toRefs(result)
}
