
import { isPageRefresh } from '@/utils'
import { defineComponent, onMounted, reactive }  from 'vue'
import Vue3Marquee from 'vue3-marquee/src/vue3-marquee.vue'

export interface LessonResults {
  lessonresultdata: Lessonresultdaum[]
}
interface StoredResults extends LessonResults {
  timestamp: number
}

const SESSION_STORE_KEY = 'exemplar-results-ticker-data'

export interface Lessonresultdaum {
  name: string
  lesson: string
  day: number
  month: number
  time: string
  GMTday: string
  GMTmonth: string
  GMTtime: string
  marks: number
}

export default defineComponent({
    components: {
        Vue3Marquee
    },
  setup() {
    const vars = reactive({
      loading: true,
      results: null as null | Lessonresultdaum[],
    });
    onMounted(() => {
      try {
        if (!isPageRefresh()) {
          const value = sessionStorage.getItem(SESSION_STORE_KEY)
          if (value) {
            const parsed: StoredResults = JSON.parse(value)
            if (parsed.timestamp + (300 * 1000) > Date.now()) {
              // data fresher than 5 min
              vars.results = parsed.lessonresultdata
              vars.loading = false
              return
            }
          }
        }
      // eslint-disable-next-line no-empty
      } catch (e) {}

      return fetch(`https://learning.exemplar-education.com/portal.free?_=${Date.now()}`)
        .then((r) => {
            if (!r.ok) {
                throw new Error(`HTTP Error ${r.status}: ${r.statusText}`)
            }
            return r.json()
        })
        .then((resp: LessonResults) => {
          vars.results = resp.lessonresultdata
          vars.loading = false
          // stick in storage for next page load
          sessionStorage.setItem(SESSION_STORE_KEY, JSON.stringify({
            timestamp: Date.now(),
            lessonresultdata: resp.lessonresultdata
          } as StoredResults))
        });
    });
    return vars;
  },
});
